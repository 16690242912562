
function createData(slno, data) {
    return { slno, data };
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return "" + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "  " + strTime;
}

function formatDateFromString(dateString) {
    let date = new Date(dateString);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return "" + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "  " + strTime;
}

function getDateString(indate) {
    return "" + (indate.getFullYear()) + "-" + (String(indate.getMonth() + 1).padStart(2, '0')) + "-" + (String(indate.getDate()).padStart(2, '0'))
}

function getSerialSplits(str) {
    var patt1 = /[0-9]/g;
    var patt2 = /[a-zA-Z]/g;
    var letters = str.match(patt2);
    var digits = str.match(patt1);

    var letterPart = "";
    for(let i=0; i<letters.length; ++i){
      letterPart += letters[i];
    }

    var numberPart = "";
    for(let i=0; i<digits.length; ++i){
      numberPart += digits[i];
    }

    return {"letterPart":letterPart, "numberPart":numberPart};
  }

exports.createData = createData;
exports.a11yProps = a11yProps;
exports.formatDate = formatDate;
exports.formatDateFromString = formatDateFromString;
exports.getDateString = getDateString;
exports.getSerialSplits = getSerialSplits;