import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import NavBar from './components/navbar';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import SignInSide from './components/signInSidePage';
import SignInOTP from './components/signInOTP';
import NotFound from './components/notfound';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import config from './config.json';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AddMeterData from './components/addMeterData';
import DataCollectUsers from './components/dataCollectUsers';
import SkippedMeterData from './components/skippedMeterData';
import MeterDataValidateSkipped from './components/meterDataValidateSkipped';

function App(props) {

  const fullScreenHandle = useFullScreenHandle();

  const history = useHistory();

  const [inProcessUser, setInProcessUser] = useState(null);

  const [loginstep, set_loginstep] = React.useState(0);

  const [role, setRole] = React.useState("");

  const [mode, setMode] = React.useState(1);

  const [skipped_meter_data_id, set_skipped_meter_data_id] = React.useState("");

  const useStyles = makeStyles((theme) => ({

    root: {
      flexGrow: 1,
    },
    open: {
      width: "calc(100vw - 240px)",
      position: "relative",
      '@media (max-width: 600px)': {
        width: "calc(100vw - 52px)",
      }
    },
    close: {
      width: "calc(100vw - 72px)",
      position: "relative",
      '@media (max-width: 600px)': {
        width: "calc(100vw - 52px)",
      }
    },    
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  

  const onNewPasswordRequired = () => {
    history.push('/setpassword');
  };

  const [authSuccess, setAuthSuccess] = useState(false);

  useEffect(() => {
    let token = window.localStorage.getItem("authToken", "");
    let storedRole = window.localStorage.getItem("role", "");      
    if(token && token.length > 0 && (storedRole == "data-collector" || storedRole == "data-collector-admin")){
      setRole(storedRole);
      setAuthSuccess(true);
    }
    else{
      setAuthSuccess(false);      
    }
  }, []);

  const onOTPSent = () => {
    console.log("onOTPSent called");
    set_loginstep(1);
  }

  const onAuthSuccess = (inrole, token, data) => {
    console.log("onAuthSuccess: ", inrole);
    if(inrole == "data-collector"){
      setRole(inrole);
      window.localStorage.setItem("authToken", token);      
      window.localStorage.setItem("role", inrole);
      window.localStorage.setItem("team", data.team);      
      setAuthSuccess(true);
    }
    else if (inrole == "data-collector-admin"){
      setRole(inrole);
      window.localStorage.setItem("authToken", token);
      window.localStorage.setItem("role", inrole);      
      window.localStorage.setItem("team", data.team);      
      setAuthSuccess(true);
    }
    else{
      window.localStorage.setItem("authToken", "");
      window.localStorage.setItem("role", "");      
      setAuthSuccess(false);
    }
  }

  const logout = () => {
    window.localStorage.setItem("authToken", "");
    setAuthSuccess(false);

    window.open("./index.html","_self")
  }

  const skippedData = () => {
    console.log("skippedData");
    console.log(props);
    setMode(2);
  }

  const myTeam = () => {
    setMode(1);
  }

  return (
    <React.Fragment>
      <FullScreen handle={fullScreenHandle}>
        <main>
          <div className={classes.root}>
            {!authSuccess &&
              <BrowserRouter>
                <Switch>
                  <Route exact path="/signin" render={(props) => <SignInSide onOTPSent={onOTPSent}  {...props} />} />
                  <Route exact path="/signinotp" render={(props) => <SignInOTP onAuthSuccess={onAuthSuccess} onNewPasswordRequired={onNewPasswordRequired} setInProcessUser={setInProcessUser} {...props} />} />
                  <Route exact path="/" render={(props) => <SignInSide onOTPSent={onOTPSent}  {...props} />} />
                  <Route exact path="/index.html" render={(props) => <SignInSide onOTPSent={onOTPSent}  {...props} />} />
                </Switch>
              </BrowserRouter>
            }

            {authSuccess &&
              < BrowserRouter >
                <NavBar logout={logout} skippedData={skippedData} myTeam={myTeam} isAdmin={(role == "data-collector-admin")} {...props} />
                <Switch>
                  <Route exact path="/not-found" component={NotFound} />

                  {role == "data-collector" && <Route exact path="/add-data" render={(props) => <div > <AddMeterData {...props} /> </div>} />}
                  {role == "data-collector" && <Route exact path="/index.html" render={(props) => <div > <AddMeterData {...props} /> </div>} />}
                  {role == "data-collector" && <Route render={() => <Redirect to="/add-data" />} />}

                  {role == "data-collector-admin" && (mode == 1) && <Route exact path="/data-collector-admin" render={(props) => <div > <DataCollectUsers {...props} /> </div>} />}
                  {role == "data-collector-admin" && (mode == 1) && <Route exact path="/index.html" render={(props) => <div > <DataCollectUsers {...props} /> </div>} />}
                  {role == "data-collector-admin" && (mode == 1) && <Route render={() => <Redirect to="/data-collector-admin" />} />}

                  {role == "data-collector-admin" && (mode == 2) && <Route exact path="/index.html" render={(props) => <div > <SkippedMeterData set_skipped_meter_data_id={set_skipped_meter_data_id} {...props} /> </div>} />}
                  {role == "data-collector-admin" && (mode == 2) && <Route exact path="/meter-data-skipped-details" render={(props) => <div > <MeterDataValidateSkipped skipped_meter_data_id={skipped_meter_data_id} {...props} /> </div>} />}
                  {role == "data-collector-admin" && (mode == 2) && <Route render={() => <Redirect to="/index.html" />} />}

                </Switch>
              </BrowserRouter>
            }

          </div>
          {(config.baseurl === 'http://someshwara-test.com:3000') &&
            <Button className="dev-btn">Development</Button>
          }
        </main>
      </FullScreen>

    </React.Fragment >
  );
}

export default App;
