import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import axios from "axios";
import config from "../config.json";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import ErrorIcon from "@material-ui/icons/Error";
import SuccessIcon from "@material-ui/icons/Check";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import ImagePreview from "./imagePreview.jsx";
import "prevent-pull-refresh";
import { v4 as uuidv4 } from "uuid";

import { Typography } from "@material-ui/core";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddMeterDataPhoto(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "calc(100%)",
        },
        paper: {
            width: "100%",
            // marginBottom: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        grid: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
            marginBottom: "10px",
        },
        total: {
            textAlign: "left",
        },
        totalDetails: {
            marginRight: "20px",
        },
        totalAttendes: {
            display: "flex",
            alignItems: "baseline",
            width: "30%",
            // marginRight: '80px'
            // borderRight: "1px solid #CACACA",
            "@media (max-width: 600px)": {
                width: "300px",
            },
        },
        progress: {
            width: "70%",
            "@media (max-width: 600px)": {
                width: "600px",
            },
        },
        h1: {
            margin: "0px",
            paddingRight: "10px",
            paddingLeft: "10px",
        },
        vl: {
            border: "1px solid #CACACA",
            height: " 40px",
        },
        search: {
            position: "relative",
        },
        searchIcon: {
            height: "100%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
        },
        inputInput: {
            // width: '90%',
            paddingLeft: "30px",
            paddingRight: "30px",
            borderBottom: "1px solid #CACACA",
        },
        inputRoot: {
            width: "100%",
        },
        button: {
            background: "white",
            textTransform: "capitalize",
        },
        exhibitor_image: {
            marginRight: "10px",
        },
        flex: {
            display: "flex",
            alignItems: "center",
        },
        dot: {
            height: "10px",
            width: "10px",
            backgroundColor: "#bbb",
            borderRadius: "50%",
            display: "inline-block",
            marginRight: "7px",
            marginLeft: "7px",
        },
        dotActive: {
            height: "10px",
            width: "10px",
            backgroundColor: "#4287F5",
            borderRadius: "50%",
            display: "inline-block",
            marginRight: "7px",
            marginLeft: "7px",
        },
        addButton: {
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            width: "70%",
            // marginRight: '80px'
            // borderRight: "1px solid #CACACA",
            "@media (max-width: 600px)": {
                width: "300px",
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const [showBackDrop, setShowBackDrop] = React.useState(false);

    const [dataUri, setDataUri] = React.useState("");
    const [step, set_step] = React.useState(0);

    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const isFullscreen = false;

    const errorDialog = (
        <Paper sx={{ m: 1 }} elevation={4} style={{ margin: 20 }}>
            <br></br>
            <div style={{ textAlign: "center" }}>
                <ErrorIcon fontSize="large" />
            </div>
            <Typography style={{ margin: 20, textAlign: "center" }}>{errorMessage}</Typography>
            <div style={{ textAlign: "center" }}>
                <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => setShowError(false)}
                    style={{ marginRight: 10 }}
                >
                    {" "}
                    OK{" "}
                </Button>
            </div>
            <br></br>
        </Paper>
    );

    const messageDialog = (
        <Paper sx={{ m: 1 }} elevation={4} style={{ margin: 20 }}>
            <br></br>
            <div style={{ textAlign: "center" }}>
                <SuccessIcon fontSize="large" />
            </div>
            <Typography style={{ margin: 20, textAlign: "center" }}>{message}</Typography>
            <div style={{ textAlign: "center" }}>
                <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setShowMessage(false);
                        props.onTypeComplete(props.type);
                    }}
                    style={{ marginRight: 10 }}
                >
                    {" "}
                    OK{" "}
                </Button>
            </div>
            <br></br>
        </Paper>
    );

    useEffect(() => {
        return () => {
            // cancel the subscription
            setShowError(false);
            setShowBackDrop(false);
            set_step(0);
        };
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShowError(false);
    };

    const handleCloseBackDrop = () => {};

    const uploadFile = async (myfile) => {
        setShowBackDrop(true);

        console.log("Preparing the upload");
        let url = config["baseurl"] + "/api/cloud/sign_s3";
        axios.defaults.headers.common["authToken"] = window.localStorage.getItem("authToken");
        try {
            const response = await axios.post(url, {
                fileName: myfile.name,
                fileType: myfile.filetype,
                folder: "datacollector",
            });

            if (response) {
                var returnData = response.data.data.returnData;
                var signedRequest = returnData.signedRequest;

                var buf = Buffer.from(myfile.file.replace(/^data:image\/\w+;base64,/, ""), "base64");

                // Put the fileType in the headers for the upload
                var options = { headers: { "x-amz-acl": "public-read", "Content-Type": myfile.filetype } };
                try {
                    // console.log(myfile);
                    // console.log(myfile.file);
                    await axios.put(signedRequest, buf, options);

                    let { data } = await axios.get(returnData.url);
                    // console.log(data.length);

                    if (!data || data.length == 0) {
                        setShowBackDrop(false);
                        return;
                    }

                    // let newFiles = [...files];
                    myfile.path = returnData.url;
                    myfile.name = myfile.file.name;
                    // console.log("myfile: ", myfile);

                    props.set_datauri(myfile.file);
                    props.set_uploaded_url(returnData.url);

                    console.log("Uploaded: ", returnData.url);

                    // Verify and make sure uploaded url have data in it.

                    setShowBackDrop(false);

                    if (props.type === "bill") {
                        setShowMessage(true);
                        setMessage("RR/Bill photo upload successfull! Next capture old meter");
                    } else if (props.type === "old_meter") {
                        setShowMessage(true);
                        setMessage("Old Meter photo upload successfull! Next capture new meter.");
                    } else if (props.type === "new_meter") {
                        // addMeterDataInDB();
                        // setMessage("All data of meter uploaded successfull!");
                        set_step(0);
                        props.onTypeComplete(props.type);
                    }

                    //

                    console.log("Response from s3 Success: ", returnData.url);
                } catch (error) {
                    console.log("ERROR: ", JSON.stringify(error));
                    setShowBackDrop(false);
                    setShowError(true);
                    setErrorMessage("Failed to Upload! Please check network and try again.");
                }
            }
        } catch (error) {
            console.log("error: ", error);
            console.log(error.response);
            setShowBackDrop(false);
            setShowError(true);
            setErrorMessage("Failed to Upload! Please check network and try again.");
            // alert(JSON.stringify(error));
        }
    };

    function handleTakePhoto(dataUri) {
        // Do stuff with the photo...
        console.log("takePhoto");
    }

    function handleTakePhotoAnimationDone(dataUri) {
        // Do stuff with the photo...
        console.log("takePhoto");
        setDataUri(dataUri);
        props.set_datauri(dataUri);

        // console.log(dataUri);
    }

    function handleCameraError(error) {
        console.log("handleCameraError", error);
    }

    function handleCameraStart(stream) {
        console.log("handleCameraStart");
    }

    function handleCameraStop() {
        console.log("handleCameraStop");
    }

    const handleSubmit = () => {
        let file = { file: dataUri, name: uuidv4() + "-b.jpg", filetype: "jpg" };
        uploadFile(file);
    };

    const handleCapture = () => {
        set_step(1);
    };

    const handleRetakePhoto = () => {
        props.set_datauri("");
        props.set_uploaded_url("");
        set_step(1);
    };

    return (
        <div className={clsx(classes.root)}>
            <div className={classes.paper}>
                {props.datauri && props.datauri.length ? (
                    <div>
                        {!props.uploaded_url && (
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRetakePhoto}
                                    style={{ marginRight: 10 }}
                                >
                                    {" "}
                                    Retake{" "}
                                </Button>

                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    style={{ marginLeft: 10 }}
                                >
                                    {" "}
                                    Upload{" "}
                                </Button>
                            </div>
                        )}
                        {props.uploaded_url && (
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRetakePhoto}
                                >
                                    {" "}
                                    Retake{" "}
                                </Button>
                            </div>
                        )}
                        <br></br>
                        <ImagePreview dataUri={props.datauri} isFullscreen={isFullscreen} />
                    </div>
                ) : (
                    <div>
                        {step === 0 && (
                            <div style={{ textAlign: "center" }}>
                                {" "}
                                <Button variant="contained" color="primary" onClick={handleCapture}>
                                    {" "}
                                    Take Photo{" "}
                                </Button>
                            </div>
                        )}

                        {step === 1 && (
                            <Camera
                                onTakePhoto={(dataUri) => {
                                    handleTakePhoto(dataUri);
                                }}
                                onTakePhotoAnimationDone={(dataUri) => {
                                    handleTakePhotoAnimationDone(dataUri);
                                }}
                                onCameraError={(error) => {
                                    handleCameraError(error);
                                }}
                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                idealResolution={{ width: 640, height: 480 }}
                                imageType={IMAGE_TYPES.JPG}
                                imageCompression={0.77}
                                isMaxResolution={true}
                                isImageMirror={false}
                                isSilentMode={false}
                                isDisplayStartCameraError={true}
                                isFullscreen={isFullscreen}
                                sizeFactor={1}
                                onCameraStart={(stream) => {
                                    handleCameraStart(stream);
                                }}
                                onCameraStop={() => {
                                    handleCameraStop();
                                }}
                            />
                        )}
                    </div>
                )}

                {/* </Paper> */}
            </div>

            <Backdrop className={classes.backdrop} open={showError} onClick={handleCloseBackDrop}>
                <Box sx={{ height: 180 }}>
                    <Box sx={{ width: `calc(100px + 16px)` }}>
                        <Slide direction="up" in={showError} mountOnEnter unmountOnExit>
                            {errorDialog}
                        </Slide>
                    </Box>
                </Box>
            </Backdrop>

            <Backdrop className={classes.backdrop} open={showMessage} onClick={handleCloseBackDrop}>
                <Box sx={{ height: 180 }}>
                    <Box sx={{ width: `calc(100px + 16px)` }}>
                        <Slide direction="up" in={showMessage} mountOnEnter unmountOnExit>
                            {messageDialog}
                        </Slide>
                    </Box>
                </Box>
            </Backdrop>

            <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
