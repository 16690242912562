import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import lockImage from '../assets/svg/ss/unlock.svg';
import axios from 'axios';
import config from "../config.json";
import OTPInput, { ResendOTP } from "otp-input-react";
import Snackbar from '@material-ui/core/Snackbar';

import "./auth.js";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <a color="inherit" href="https://someshwara.com/"> Someshwara Software Pvt Ltd</a>
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        marginTop: -8
    },
    header: {
        position: 'absolute',
        width: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 10px'
    }
}));

export default function SignInOTP(props) {
    const classes = useStyles();
    const [phone, set_phone] = React.useState("");
    const [phoneNumberError, setPhoneNumberError] = React.useState(null);
    const [contactingServer, setContactingServer] = React.useState(false);

    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    // const [otp, setOtp] = React.useState();
    const [OTP, setOTP] = React.useState("");

    useEffect(() => {
        console.log("signInOTP entered");

        let storeNum = window.localStorage.getItem("phone");
        if (!storeNum || storeNum.length < 10) {
            props.history.push('./signin');
            return;
        }

        set_phone(storeNum);

    }, []);

    const resendOTPSubmit = (e) => {
        console.log("resendOTPSubmit 1");

        let errorOccured = false;
        if (!phone || phone.length < 10) {
            setPhoneNumberError("Not a valid Phone number");
            errorOccured = true;
        }

        console.log("resendOTPSubmit 2");

        if (errorOccured)
            return;

        console.log("resendOTPSubmit 3");

        resendOTP();
    }

    const resendOTP = async () => {

        console.log("resendOTPSubmit 4");

        setContactingServer(true);
        try {
            let url = config["baseurl"] + "/api/datacollectuser/getotpforphone";

            let postObj = {};
            postObj["phone"] = phone;

            const response = await axios.post(url, postObj);

            console.log(response);

            setContactingServer(false);
            // Its success lets move to otp screen
        } catch (error) {
            console.log("signin 4");
            if (error.response)
                setPhoneNumberError(error.response.data["message"]);
            else
                setPhoneNumberError(error.message);

            setContactingServer(false);
            return;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let errorOccured = false;
        if (!phone || phone.length < 10) {
            setPhoneNumberError("Not a valid Phone number");
            errorOccured = true;
        }

        if (errorOccured)
            return;

        loginWithOTP();
    }

    const loginWithOTP = async () => {
        setContactingServer(true);

        try {
            let url = config["baseurl"] + "/api/datacollectuser/verifyotp";

            let postObj = {};
            postObj["phone"] = phone;
            postObj["otp"] = "" + OTP;

            const response = await axios.post(url, postObj);

            console.log(response);

            setContactingServer(false);
            // Its success lets move to otp screen
            props.onAuthSuccess(response.data.role, response.data.token, response.data);
        } catch (error) {
            console.log("signin 4");
                
            console.log(error);
            setErrorMessage("Please check OTP and Phone number");
            setShowError(true);

            setContactingServer(false);
            return;
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    return (
        <div>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />

                {/* <Grid item xs={12} sm={6} className={classes.header}>
                <img src={LogoIcon} width='120' alt="" />
                <LanguageSelect language={language} themeChanged={themeChanged}></LanguageSelect>
            </Grid> */}

                {/* <Grid item xs={false} sm={6} md={6}>
                <Hidden xsDown>
                    <CoverPage></CoverPage>
                </Hidden>
            </Grid> */}
                <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <img src={lockImage} width='30px' height='30px' alt=""></img>
                        <Typography component="h1" variant="h5">
                            OTP
                        </Typography>
                        <Typography component="h6" variant="subtitle2">
                            OTP sent to phone number : {phone}
                        </Typography>
                        {/* <form className={classes.form} noValidate onSubmit={handleSubmit}> */}
                        <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
                            <br></br>
                            <OTPInput
                                value={OTP}
                                onChange={setOTP}
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                autoFocus
                                style={{ fontSize: 30 }}
                            />
                        </Box>
                        <br></br>
                        <ResendOTP maxTime={5} onResendClick={resendOTPSubmit} />
                        <br></br>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={contactingServer}
                            onClick={handleSubmit}
                        >
                            Next
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link to="/forgotpassword" > Forgot password? </Link>
                            </Grid>
                        </Grid> */}
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                        {/* </form> */}
                    </div>
                </Grid >
            </Grid >

            <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}