import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/svg/logos/logo.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import profileImage from '../assets/svg/ss/profile.svg';
import stopWatchImage from '../assets/svg/ss/stopwatch.svg';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import config from "../config.json";
import Utils from "./utils.js";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginRight: 12,
        marginLeft: 12,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // background: 'transparent',
        backgroundColor: 'white',
        // boxShadow: 'true',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '48px',
        '@media (max-width: 420px)': {
            height: "39px",
        }
    },
    appBarRight: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between'
    },
    appBarShift: {
        width: `calc(100%)`,
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        margin: '8px 12px',
        // marginLeft: 12,
        color: 'black',
        '@media (max-width: 420px)': {
            margin: '6px 4px',
        }
    },
    hide: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    info: {
        '@media (max-width: 420px)': {
            display: "none",
        }
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 150,
    },

}));

export default function NavBar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [showBackDrop, setShowBackDrop] = React.useState(false);
    const [currentProject, setCurrentProject] = React.useState(-1);
    const [currentWarehouse, setCurrentWarehouse] = React.useState(-1);
    const [roles, set_roles] = React.useState([]);

    useEffect(() => {
    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOutLocal = () => {
        setAnchorEl(null);
        props.logout();
    }

    const handleSkippedDataLocal = () => {
        setAnchorEl(null);
        props.skippedData();
    }

    const handleMyTeamLocal = () => {
        setAnchorEl(null);
        props.myTeam();
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.drawerOpen,
                })}
            >
                <div className={classes.appBarRight}>
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <img src={profileImage} width='25' alt="" />
                        </IconButton>

                    </div>
                    <div>
                        <Typography style={{ color: "#000000", paddingTop: 10 }}>SmartEx</Typography>
                    </div>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {props.isAdmin && <Divider />}
                        {props.isAdmin && <MenuItem onClick={handleMyTeamLocal}>My Team</MenuItem>}
                        {props.isAdmin && <Divider />}
                        {props.isAdmin && <MenuItem onClick={handleSkippedDataLocal}>Skipped Data</MenuItem>}
                        <Divider />
                        <MenuItem onClick={handleSignOutLocal}>SignOut</MenuItem>
                        <Divider />
                    </Menu>
                </div>

            </AppBar>

            <Backdrop className={classes.backdrop} open={showBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div >
    );
}
