import * as React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RRNumberDialog(props) {
  const [open, setOpen] = React.useState(true);

  const [rrnumber, set_rrnumber] = React.useState("");
  const [rrnumber_error, set_rrnumber_error] = React.useState(null);

  const handleClose = () => {
    if (rrnumber.length == 0) {
      set_rrnumber_error("RR Number is mandatory");
      return;
    }
    props.setRRNumber(rrnumber);
    props.close(true);
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Meter Replacement</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter RR Number to start with</DialogContentText>
          <TextField
            autoFocus
            size="small"
            id="formControl_rrnumber"
            value={rrnumber}
            label="RR Number"
            variant="standard"
            margin="dense"
            fullWidth
            onChange={(event) => {
              set_rrnumber(event.target.value);
              set_rrnumber_error(null);
            }}
          />
          {rrnumber_error && <Alert severity="error"> {rrnumber_error} </Alert>}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
