import React, { useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import lockImage from '../assets/svg/ss/unlock.svg';
import axios from 'axios';
import config from "../config.json";

import "./auth.js";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <a color="inherit" href="https://someshwara.com/"> Someshwara Software Pvt Ltd</a>
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        marginTop: -8
    },
    header: {
        position: 'absolute',
        width: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 10px'
    }
}));

export default function SignInSidePage(props) {
    const classes = useStyles();
    const phone = useRef(null);
    const [phoneNumberError, setPhoneNumberError] = React.useState(null);
    const [contactingServer, setContactingServer] = React.useState(false);
    
    useEffect(() => {
        console.log("signInSidePage entered");
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        let errorOccured = false;
        if (phone.current.value.length < 10) {
            setPhoneNumberError("Not a valid Phone number");
            errorOccured = true;
        }

        if (errorOccured)
            return;

        signIn();
    }

    const signIn = async () => {
        setContactingServer(true);
        try {
            let url = config["baseurl"] + "/api/datacollectuser/getotpforphone";

            let postObj = {};
            postObj["phone"] = "+91" + phone.current.value.trim();
            window.localStorage.setItem("phone", postObj["phone"]);

            const response = await axios.post(url, postObj);

            console.log(response);

            setContactingServer(false);
            // Its success lets move to otp screen

            props.history.push('./signinotp');


            // console.log(response);
            // console.log(response.data.authToken);
            // console.log(response.data.name);
            // window.localStorage.setItem("authToken", response.data["authToken"]);
            // // window.localStorage.setItem("authToken", response.data["authToken"]);
            // // postObj["password"] = password.current.value.trim();
            // window.localStorage.setItem("name", response.data.name);

            // if (response.data["needPasswordReset"] === true) {
            //     props.setInProcessUser(response.data);
            //     props.history.push('/setpassword');
            // } else {
            //     try {
            //         url = config["baseurl"] + "/api/user/profile";
            //         axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
            //         const response = await axios.get(url);
            //         const profileResp = response.data;
            //         console.log(profileResp);
            //         window.localStorage.setItem("profile", JSON.stringify(profileResp));

            //         setContactingServer(false);
            //         props.onAuthSuccess();
            //     }
            //     catch (e) {
            //         console.log("error in profile API");
            //         throw e;
            //         return;
            //     }
            // }
        } catch (error) {
            console.log("signin 4");
            if (error.response)
                setPhoneNumberError(error.response.data["message"]);
            else
                setPhoneNumberError(error.message);

            setContactingServer(false);
            return;
        }
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />

            {/* <Grid item xs={12} sm={6} className={classes.header}>
                <img src={LogoIcon} width='120' alt="" />
                <LanguageSelect language={language} themeChanged={themeChanged}></LanguageSelect>
            </Grid> */}

            {/* <Grid item xs={false} sm={6} md={6}>
                <Hidden xsDown>
                    <CoverPage></CoverPage>
                </Hidden>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img src={lockImage} width='30px' height='30px' alt=""></img>
                    <Typography component="h1" variant="h5">
                        Phone number
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            inputRef={phone}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="Phone Number"
                            name="phone"
                            autoComplete="phone"
                            autoFocus
                        />
                        {phoneNumberError && <Alert className={classes.alert} severity="error"> {phoneNumberError} </Alert>}
                        {/* <OTPInput
      value={OTP}
      onChange={setOTP}
      OTPLength={4}
      otpType="number"
      disabled={false}
      style={{fontSize:30}}      
    />
    <ResendOTP handelResendClick={() => console.log("Resend clicked")} />                         */}
                        {/* <OtpInput
                            value={otp}
                            onChange={handleOTPChange}
                            numInputs={4}
                            separator={<span>-</span>}
                            style={{fontSize: '20px !important;'}}
                        />                         */}
                        {/* <TextField
                            inputRef={password}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        /> */}
                        {/* {passwordError && <Alert className={classes.alert} severity="error"> {passwordError} </Alert>} */}
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" checked={rememberMeChecked}
                                onChange={handleChange} />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={contactingServer}
                        >
                            Next
            </Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link to="/forgotpassword" > Forgot password? </Link>
                            </Grid>
                        </Grid> */}
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid >
        </Grid >
    );
}