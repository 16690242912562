import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import 'date-fns';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AddMeterDataPhoto from './addMeterDataPhoto';
import 'prevent-pull-refresh';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import BackupIcon from '@material-ui/icons/Backup';
import Slide from '@material-ui/core/Slide';
import ErrorIcon from '@material-ui/icons/Error';
import SuccessIcon from '@material-ui/icons/Check';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import config from "../config.json";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import RRNumberDialog from './rrNumberDialog';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddMeterData(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: 'calc(100%)',
            marginTop: 40
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            paddingLeft: 20,
            paddingRight: 20,
        },
        papernew: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            outline: 'none',
            padding: '10px 20px',
            width: '100%',
            borderRadius: '5px',
            overflow: 'auto',
            depth: 1,
            marginTop: '10px',
            marginBottom: '10px',
        },
        grid: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            marginBottom: '10px',
        },
        inputFields: {
            marginTop: 10,
        },
        submit: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '15px',
            margin: '5px',
        },
        formControl: {
            marginTop: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
          },
    }));

    const classes = useStyles();
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [value, setValue] = React.useState(0);

    const [latlong, set_latlong] = React.useState("-10.000,10.000");
    const [RRNumber, setRRNumber] = React.useState("");
    const [bill_datauri, set_bill_datauri] = React.useState("");
    const [old_meter_datauri, set_old_meter_datauri] = React.useState("");
    const [new_meter_datauri, set_new_meter_datauri] = React.useState("");

    const [bill_image_url, set_bill_image_url] = React.useState("");
    const [old_meter_bill_image_url, set_old_meter_bill_image_url] = React.useState("");
    const [new_meter_bill_image_url, set_new_meter_bill_image_url] = React.useState("");

    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const [showGeoMessage, setShowGeoMessage] = React.useState(false);
    const [errorGeoMessage, setErrorGeoMessage] = React.useState(null);

    const [showRRNumberDialog, setShowRRNumberDialog] = React.useState(true);

    const [showBackDrop, setShowBackDrop] = React.useState(false);

    const errorDialog = (
        <Paper sx={{ m: 1 }} elevation={4} style={{ margin: 20 }}>
            <br></br>
            <div style={{ textAlign: "center" }}>
                <ErrorIcon fontSize='large' />
            </div>
            <Typography style={{ margin: 20, textAlign: "center" }}>{errorMessage}</Typography>
            <div style={{ textAlign: "center" }}>
            <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {setShowError(false); clearAll(); } } style={{ marginRight: 10 }}
                > Clear </Button>
                <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {setShowError(false); addMeterDataInDB(); }} style={{ marginRight: 10 }}
                > Try Again </Button>
            </div>
            <br></br>
        </Paper>
    );

    const messageDialog = (
        <Paper sx={{ m: 1 }} elevation={4} style={{ margin: 20 }}>
            <br></br>
            <div style={{ textAlign: "center" }}>
                <SuccessIcon fontSize='large' />
            </div>
            <Typography style={{ margin: 20, textAlign: "center" }}>{message}</Typography>
            <div style={{ textAlign: "center" }}>
                <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => { setShowMessage(false); setShowRRNumberDialog(true); }} style={{ marginRight: 10 }}
                > OK </Button>
            </div>
            <br></br>
        </Paper>
    );

    useEffect(() => {
        if(new_meter_bill_image_url && new_meter_bill_image_url.length > 0){
            addMeterDataInDB();
        }
        return () => {
            // cancel the subscription            
        };

    }, [new_meter_bill_image_url]);

    useEffect(() => {
        getNewGeoPosition();
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const handleGeoClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowGeoMessage(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onTypeComplete = (type) => {
        if (type === "bill") {
            setValue(1);
        }
        else if (type === "old_meter") {
            setValue(2);
        }
        else if (type === "new_meter") {

            // addMeterDataInDB();
        }
    }

    const handleCloseBackDrop = () => {

    };

    const getNewGeoPosition = () => {
        navigator.geolocation.getCurrentPosition(
            function(position) {
              console.log(position);
              set_latlong(""+position.coords.latitude+","+position.coords.longitude);
            },
            function(error) {
                setShowGeoMessage(true);
                setErrorGeoMessage("Error Code = " + error.code + " - " + error.message);

              console.error("Error Code = " + error.code + " - " + error.message);
            }
          );
    }

    const clearAll = () => {
        set_bill_datauri("");
        set_old_meter_datauri("");
        set_new_meter_datauri("");
        set_bill_image_url("");
        set_old_meter_bill_image_url("");
        set_new_meter_bill_image_url("");
        setValue(0);
        setRRNumber("");

        getNewGeoPosition();
    }

    const addMeterDataInDB = async () => {
        if(RRNumber.length == 0){
            setShowRRNumberDialog(true);
            return;
        }

        setShowBackDrop(true);        

        console.log("Preparing the upload");
        let url = config["baseurl"] + "/api/meterreplacementdata/add";
        axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");

        try {
            const response = await axios.post(url, {
                "billNumber":RRNumber,
                "billimagepath": bill_image_url,
                "oldmeterimagepath": old_meter_bill_image_url,
                "newmeterimagepath": new_meter_bill_image_url,
                "latlong": latlong
            });

            console.log(response);

            if (response) {

                setShowBackDrop(false);

                setShowMessage(true);

                clearAll();

                setMessage("All data of meter uploaded successfull!");
            }
            else {
                setShowBackDrop(false);
                setShowError(true);
                setErrorMessage("Failed to Save record! Please check network and try again.");
            }
        }
        catch (error) {
            console.log("error: ", error);
            setShowBackDrop(false);
            setShowError(true);
            setErrorMessage("Failed to Save record! Please check network and try again.");
            // alert(JSON.stringify(error));
        }
    };

    const handleCloseRRNumberDialog = () => {
        setShowRRNumberDialog(false);
    }

    return (
        <div className={clsx(classes.root)}>

            <AppBar position="static" >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                    <Tab icon={bill_image_url ? <CheckIcon /> : <BackupIcon />} label="RR/Bill" {...a11yProps(0)} />
                    <Tab icon={old_meter_bill_image_url ? <CheckIcon /> : <BackupIcon />} label="Old Meter" {...a11yProps(1)} />
                    <Tab icon={new_meter_bill_image_url ? <CheckIcon /> : <BackupIcon />} label="New Meter" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <span>RR Number: {RRNumber}</span>
                <AddMeterDataPhoto
                    type={"bill"}
                    datauri={bill_datauri}
                    set_datauri={set_bill_datauri}
                    uploaded_url={bill_image_url}
                    set_uploaded_url={set_bill_image_url}
                    onTypeComplete={onTypeComplete}
                    {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AddMeterDataPhoto
                    type={"old_meter"}
                    datauri={old_meter_datauri}
                    set_datauri={set_old_meter_datauri}
                    uploaded_url={old_meter_bill_image_url}
                    set_uploaded_url={set_old_meter_bill_image_url}
                    onTypeComplete={onTypeComplete}
                    {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AddMeterDataPhoto
                    type={"new_meter"}
                    datauri={new_meter_datauri}
                    set_datauri={set_new_meter_datauri}
                    uploaded_url={new_meter_bill_image_url}
                    set_uploaded_url={set_new_meter_bill_image_url}
                    onTypeComplete={onTypeComplete}
                    {...props} />
            </TabPanel>

            <Backdrop className={classes.backdrop} open={showError} onClick={handleCloseBackDrop}>
                <Box sx={{ height: 180 }}>
                    <Box sx={{ width: `calc(100px + 16px)` }}>
                        <Slide direction="up" in={showError} mountOnEnter unmountOnExit>
                            {errorDialog}
                        </Slide>
                    </Box>
                </Box>
            </Backdrop>

            <Backdrop className={classes.backdrop} open={showMessage} onClick={handleCloseBackDrop}>
                <Box sx={{ height: 180 }}>
                    <Box sx={{ width: `calc(100px + 16px)` }}>
                        <Slide direction="up" in={showMessage} mountOnEnter unmountOnExit>
                            {messageDialog}
                        </Slide>
                    </Box>
                </Box>
            </Backdrop>

            <Snackbar open={showError} autoHideDuration={60000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={showGeoMessage} autoHideDuration={60000} onClose={handleGeoClose}>
                <Alert onClose={handleGeoClose} severity="error">
                    {errorGeoMessage}
                </Alert>
            </Snackbar>

            {showRRNumberDialog &&  <RRNumberDialog close={handleCloseRRNumberDialog} setRRNumber={setRRNumber} {...props}/>}

            <Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div >
    );
}
